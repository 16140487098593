<template>
  <v-container id="crud" fluid tag="section">
    <v-form ref="emuleUserForm" v-model="valid" lazy-validation>
      <v-row class="my-2 px-0 mx-0">
        <v-col cols="auto" md="12" class="px-0 mx-0">
          <v-alert
            outlined
            type="info"
            colored-border
            color="info"
            elevation="2"
             class="mb-0 custom-border"
          >
            <p style="font-weight: 400; font-size: 16px">
              La emulación de usuario permite acceder a todas las funcionalidades del rol seleccionado.<br>
              Para finalizar la emulación, haga click en "Salir emulación" en la parte inferior del menú.
            </p>
          </v-alert>
        </v-col>                
      </v-row>
      <v-row class="my-2 px-0 mx-0">
        <v-col cols="auto" md="12" class="px-0 mx-0">         
            <p style="font-weight: 600; font-size: 18px">
              Emulación de usuarios              
            </p>
            <p style="font-weight: 300; font-size: 14px">
              Seleccione el tipo de usuario y los datos para emular su perspectiva en el portal 
            </p>
        </v-col>                
      </v-row>      
      <Loading  v-if="isLoading" :isLoading="isLoading" :loadingMsg="loadingMsg" />
      <v-radio-group v-model="selectedOption" >
        <v-row>      
          <v-col  cols="12" md="3" class="px-0 mx-1 bordered-box d-flex flex-column">
            <div class="d-flex justify-end w-100">
            <v-radio value="acara_mj"></v-radio>
            </div>
            <p class="text-center font-weight-bold w-100 text-gray" style="font-size: 18px; width: 100%;">
              Clientes MJ y ACARA
            </p>
          </v-col>
          <v-col cols="12" md="3" class="px-0 mx-1 bordered-box d-flex flex-column">
            <div class="d-flex justify-end w-100">
            <v-radio value="aduana"></v-radio>
            </div>
            <p class="text-center font-weight-bold w-100 text-gray" style="font-size: 18px; width: 100%;">
              Delegación Aduana
            </p>
          </v-col>
          <v-col cols="12" md="3" class="px-0 mx-1 bordered-box d-flex flex-column">
            <div class="d-flex justify-end w-100">
            <v-radio value="anmac"></v-radio>
            </div>
            <p class="text-center font-weight-bold w-100 text-gray" style="font-size: 18px; width: 100%;">
              Delegación ANMAC
            </p>
          </v-col>            
        </v-row>  
      </v-radio-group>
    <br>
      <!-- Mostrar input solo si la opción seleccionada es "opcion1" -->
    <v-row v-if="selectedOption === 'acara_mj'">  
      <v-col cols="12" md="6" class="px-0 mx-1"> 
        
        <v-expand-transition>
          <div class="d-flex flex-column">
            Cliente
            <v-text-field          
            v-model="codigo"           
            placeholder="Ingrese código de cliente"
            outlined
            :rules="[$rulesRequerido]"              
          ></v-text-field>
          </div>
          
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-row v-if="selectedOption === 'aduana'">  
      <v-col cols="auto" md="6" class="px-0 mx-3"> 
        <v-expand-transition>
          <div class="d-flex flex-column">
            Delegaciones Aduana
            <v-autocomplete
              :items="delegacionesAduana"
              item-value="CardCode"
              :item-text="formatItemText"
              label=""
              placeholder="Seleccione una delegación"
              v-model="delegacionAduana"
              :rules="[$rulesRequerido]"              
              return-object
              outlined              
            ></v-autocomplete>
          </div>          
            
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-row v-if="selectedOption === 'anmac'">  
      <v-col cols="auto" md="6" class="px-0 mx-3"> 
        <v-expand-transition>          
          <div class="d-flex flex-column">
            Delegaciones ANMAC
            <v-autocomplete
              :items="delegacionesAnmac"
              item-value="Code"
              :item-text="formatItemText"
              placeholder="Seleccione una delegación"
              v-model="delegacionAnmac"
              :rules="[$rulesRequerido]"              
              return-object
              outlined
            ></v-autocomplete>
          </div>
            
        </v-expand-transition>
      </v-col>
    </v-row>
    
    <v-row v-if="selectedOption">
      <v-col class="text-right" cols="12" md="12" sm="12">         
        <v-btn color="primary" :disabled="!this.valid" @click="validateForm()"
          >Confirmar</v-btn
        >
      </v-col>
    </v-row>
    <dialog-confirmar
      v-model="dialogs['emularUsuario']"
      @input="dialog = $event"
      title="Emular nueva sesión de usuario"
      :info="textInfoDialog"
      icon="mdi-alert-circle-outline"
      color="#F44336"
      @confirm="emularUser()"
    />
    <dialog-validate
      v-model="dialogs['userExists']"
      @input="dialog = $event"
      title="Error al consultar datos del usuario"
      info="Verifique que el usuario exista en SAP y en Portal"
      icon="mdi-alert-circle-outline"
      color="#ffc107"
    />    
  </v-form>
  </v-container>
</template>

<script>
function title() {
  return "Emular User";
}
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import DialogValidate from "@/views/dialogs/Validate.vue";

import Loading from "@/views/components/Loading.vue";
import { mapActions } from "vuex";
export default {
  components: {    
    Loading,
    DialogConfirmar,  
    DialogValidate      
  },
  data: (vm) => ({    
    delegacionAduana: null,
    delegacionAnmac: null,
    routeDelegacionesAnmac:'delegacionesAnmac',
    routeDelegacionesAduana:'delegacionesAduana',
    textInfoDialog:'',    
    codigo:null,
    valid: false,
    selectedOption: false,
    delegacionesAduana:[],
    delegacionesAnmac:[],
    title: title(),
    routeBp: "bussinessPartnerClient",
    routeKey: "usuarioByUsername",
    today: new Date().toISOString().substr(0, 10),
    search: "",
    dialogs: {
      emularUsuario: false,  
      userExists: false    
    },
    isLoading: false,
    loadingMsg:"obteniendo datos...",
    
  }),  
 created() {
    console.log("Componente " + this.title + " creado");
    this.getDelegacionesAnmac();
    this.getDelegacionesAduana();    
},

  methods: {
    validateForm() {
      if (this.$refs.emuleUserForm.validate()) {
        this.fetchUserData();
      }
    },
    formatItemText(item) {
      if(this.selectedOption == 'anmac'){
        return `${item.Code} - ${item.Name}`;
      }

      return `${item.CardCode} - ${item.CardName}`;
      
    },
    ...mapActions({
      emuleUserAction: "emuleUser",      
    }),
    async emularUser(){
      try{
        Swal.alertGetInfo("Emulando Usuario "+this.user.CardName);      
        this.emuleUserAction(this.user);
        await this.$delay(3000);
        Swal.close();      
        this.$router.push('/');
      }
      catch(e){
        console.log(e);
        Swal.close();
      }
      
      
    },
    async fetchUserData(){

      try{
      let username = '';

      switch(this.selectedOption){
        case 'acara_mj':
          username = this.codigo;                            
        break;
        case 'aduana':
          username = this.delegacionAduana.CardCode;       
        break;
        case 'anmac':
          username = this.delegacionAnmac.Code;       
        break;
      }
      username = username.toUpperCase();
      Swal.alertGetInfo("Buscando datos del usuario");
      const [response1, response2] = await Promise.all([
          this.$axiosApi.getByCriteria(this.routeBp, username), //verificar info en bussinesspartner
          this.$axiosApi.getByCriteria(this.routeKey, username) //verificar info en keycloak
        ]);

        // Verificamos que ambas peticiones devuelvan datos        
        if (response1.data.data && response2.data.data) {
          Swal.close();
          console.log('SAP:', response1.data);
          console.log('keycloak:', response2.data.data);

          this.textInfoDialog = `¿Desea emular la sesión del usuario <b>${response1.data.data.CardName} (${response1.data.data.CardCode})</b>?`;
          this.dialogs.emularUsuario = true;
          this.user = response1.data.data;
          
        } else {
          Swal.close();
          this.dialogs.userExists = true;
        }
      } catch (error) {
        Swal.close();
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se encontraron los datos requeridos.',
        });
      } 
      
    },

    async getDelegacionesAnmac(){
      await this.$axiosApi
        .get(this.routeDelegacionesAnmac)
        .then((r) => {
          if (r.data) {            
            this.delegacionesAnmac = r.data.data;
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    },
    
    async getDelegacionesAduana(){
      await this.$axiosApi
        .get(this.routeDelegacionesAduana)
        .then((r) => {
          if (r.data) {            
            this.delegacionesAduana = r.data.data;
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>

<style scoped>

.underline-text {
  text-decoration: underline;
  cursor: pointer;
}

.custom-border {
  border: 2px solid #2196F3 !important;
}

.bordered-box {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px; /* Opcional, redondea las esquinas */
}
.text-gray {
  color: gray;
}
</style>
